import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
//import CopyBlock from "../../components/Copy/CopyBlock"
//import InfoBar from '../../components/Utilities/InfoBar'

class HomeAwayStats extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Home and Away Goals and Stats - Messi vs Ronaldo"}
          description={`Compare Messi and Ronaldo's goals, assists, hat-tricks and lots of other stats in home games, away games and at neutral venues.`}
          canonicalPath={`/all-time-stats/home-away-stats/`}
        />

        <h1>
        Home and Away Goals and Stats <span className="sr-only">- Messi vs Ronaldo</span>
        </h1>

        

        <CompetitionBlock competition="All Time Home Games" type="all" mdata={mdata} rdata={rdata} perfmsg="[comps] since 2009/10" />

        <CompetitionBlock competition="All Time Away Games" type="all" mdata={mdata} rdata={rdata} perfmsg="[comps] since 2009/10" />

        <CompetitionBlock competition="All Time Neutral Games" type="all" mdata={mdata} rdata={rdata} perfmsg="[comps] since 2009/10" />

        <CompetitionBlock competition="Club Home Games" type="all" mdata={mdata} rdata={rdata} perfmsg="[comps] since 2009/10" />

        <CompetitionBlock competition="Club Away Games" type="all" mdata={mdata} rdata={rdata} perfmsg="[comps] since 2009/10" />

        <CompetitionBlock competition="Club Neutral Games" type="all" mdata={mdata} rdata={rdata} perfmsg="[comps] since 2009/10" />

        <CompetitionBlock competition="International Home Games" type="all" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="International Away Games" type="all" mdata={mdata} rdata={rdata} />

        <CompetitionBlock competition="International Neutral Games" type="all" mdata={mdata} rdata={rdata} />

        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />
          
          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/home-away/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/home-away/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
        }
      }
    }
  }
`

export default HomeAwayStats
